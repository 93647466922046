import * as React from 'react';

function FeaturesSecond({ fill, stroke, fillOpacity, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="64"
      height="57"
      viewBox="0 0 64 57"
      fill="none"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.0002 0L63.1771 56.25H0.823242L32.0002 0Z"
        fill={fill ?? 'white'}
        fillOpacity={fillOpacity ?? '0.5'}
      />
      <path
        d="M45.9367 7.75633L45.5001 6.97403L45.0635 7.75633L32.5061 30.2563L32.0911 31H32.9428H58.0575H58.9091L58.4941 30.2563L45.9367 7.75633Z"
        stroke={stroke ?? '#39FFC2'}
      />
    </svg>
  );
}

const MemoFeaturesSecond = React.memo(FeaturesSecond);
export default MemoFeaturesSecond;
