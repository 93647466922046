import * as React from 'react';

function PartnershipCard({
  fill,
  stroke,
  opacity,
  strokeMiterlimit,
  strokeWidth,
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="prefix__partner_image"
      data-name="partner image"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 150"
      {...rest}
    >
      <path fill={fill ?? '#fff'} opacity={opacity ?? '.3'} d="M0 77h46v73H0z" />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        transform="rotate(-90 125.5 138.5)"
        d="M114 115.5h23v46h-23z"
      />
      <path
        fill="none"
        stroke={stroke ?? '#39ffc2'}
        strokeMiterlimit={strokeMiterlimit ?? 10}
        strokeWidth={strokeWidth ?? 2}
        d="M103.5 70.5v-44h44v44z"
      />
      <circle fill={fill ?? '#fff'} opacity={opacity ?? '.3'} cx={176.75} cy={48.5} r={23} />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        d="M74.7 127a23 23 0 00-23 23h46a23 23 0 00-23-23z"
      />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        transform="rotate(-90 176.75 138.5)"
        d="M165.25 115.5h23v46h-23z"
      />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        transform="rotate(-90 23 11.5)"
        d="M11.5-11.5h23v46h-23z"
      />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        transform="rotate(-90 74.7 11.5)"
        d="M63.2-11.5h23v46h-23z"
      />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        transform="rotate(-90 176.75 99.5)"
        d="M153.75 76.5h46v46h-46z"
      />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        transform="rotate(-90 74.7 99.5)"
        d="M51.7 76.5h46v46h-46z"
      />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        transform="rotate(-90 176.75 11.5)"
        d="M165.25-11.5h23v46h-23z"
      />
    </svg>
  );
}
const MemoPartnershipCard = React.memo(PartnershipCard);
export default MemoPartnershipCard;
