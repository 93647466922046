import React from 'react';
import styled from '@emotion/styled';
import { Container, Grid, Paper, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { ResourceCard, ResourceCardProps } from '../ResourceCard';
import { Typography } from '../../atoms/Typography';

interface UspWrapperProps {
  /**
   * Background image to show
   */
  backgroundImage?: string;
  /**
   * Background color to show
   */
  backgroundColor?: string;
  /**
   * Background gradient to show
   */
  backgroundGradient?: string;
  /**
   * Background Position
   */
  backgroundPosition?: string;
  /**
   * Background image Size
   */
  backgroundImageSize?: string;
  /**
   * Font color for dark background
   */
  fontColor?: string;
  /**
   * To show dotted images
   */
  hasExtraBackgroundImage?: boolean;
  /**
   * Theme for sizing
   */
  theme?: Theme;
}

export interface UspProps extends UspWrapperProps {
  /**
   * Title text
   */
  title: string;
  /**
   * List of items
   */
  uspCards: ResourceCardProps[];
}

const UspWrapper = styled(Paper)<UspWrapperProps>`
  padding: ${({ theme }) => theme.spacing(20)} ${({ theme }) => theme.spacing(3)};
  background-image: ${({ backgroundImage, backgroundGradient }) =>
    `url(${backgroundImage}) , ${backgroundGradient}`};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
  background-position: ${({ backgroundPosition }) => backgroundPosition || 'center'};
  background-repeat: no-repeat;
  background-size: ${({ backgroundImageSize, backgroundGradient }) =>
    `${backgroundImageSize} ${backgroundGradient ? ', contain' : null}`};
  box-shadow: none;
  border-radius: 0;
  position: relative;

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(/assets/images/dotted-wave-white.svg);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 567px;
    display: ${({ hasExtraBackgroundImage }) => (hasExtraBackgroundImage ? 'block' : 'none')};
  }

  &:before {
    transform: rotate(180deg);
  }

  h1,
  .MuiGrid-item:nth-child(-n + 2) {
    margin-bottom: ${({ theme }) => theme.spacing(10)};
  }

  ${({ theme, backgroundGradient }) => `${theme.breakpoints.down('md')} {
    background-image: ${backgroundGradient};
    background-size: contain;
    padding: ${theme.spacing(20)} 0;

    &:before,
    &:after {
      background-size: contain;
    }

    .MuiGrid-item:not(:last-child) {
      margin-bottom: ${theme.spacing(10)};
    }
  }`}

  ${({ theme }) => `${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(15)} 0;

    h1, .MuiGrid-item:not(:last-child) {
      margin-bottom: ${theme.spacing(15)};
    }  
  }`}
`;

const TextWrapper = styled.div`
  display: inline-block;
  & .subtitle {
    display: flex;
    &-inner {
      flex-grow: 1;
      width: 0;
    }
  }
`;

export const Usp: React.FC<UspProps> = ({ title, uspCards, ...rest }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  return (
    <UspWrapper {...rest} theme={theme}>
      <Container maxWidth="xl" sx={{ position: 'relative', zIndex: '1' }}>
        <Typography component="h1" size="h2" color="inherit" textAlign="center">
          {title}
        </Typography>
        {uspCards && uspCards.length > 0 && (
          <Grid container direction="row" flexDirection="row" columnSpacing={30}>
            {uspCards.map((item, index) => (
              <Grid item container xs={12} md={6} key={index}>
                <ResourceCard {...item} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </UspWrapper>
  );
};
