import React from 'react';
import styled from '@emotion/styled';
import { BiImage } from 'react-icons/bi';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Typography } from '../../atoms/Typography';
import MemoGitcoinIcon from '../../../icons/Gitcoin';
import MemoDevpostIcon from '../../../icons/Devpost';
import MemoDespaceIcon from '../../../icons/Despace';

interface CustomCardProps {
  theme: Theme;
  direction: 'column' | 'column-reverse' | 'row' | 'row-reverse';
}
const CustomCard = styled(Card)<CustomCardProps>`
  flex: 1 0 25%;
  &.hasBorder {
    border: solid 1px ${({ theme }) => theme.palette.grey[300]};
  }

  .MuiCardMedia-root {
    height: ${({ theme }) => theme.spacing(23.875)};
    background-color: ${({ theme }) => theme.palette.secondary.light};
    font-size: ${({ theme }) => theme.spacing(23.125)};
    color: ${({ theme }) => theme.palette.grey[300]};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.MuiCard-root .MuiCardContent-root {
    font-weight: normal;
    padding: ${({ theme }) => theme.spacing(4)};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &[direction='row'],
  &[direction='row-reverse'] {
    .MuiCardMedia-root {
      height: auto;
    }
  }

  h5 {
    margin-bottom: 0;
  }

  ${({ theme }) => `${theme.breakpoints.between('sm', 'md')} {
    .MuiCardMedia-root {
      height: auto;
    }
  }`}

  ${({ theme }) => `${theme.breakpoints.down('sm')} {
    &.MuiCard-root .MuiCardContent-root {
      padding: ${theme.spacing(3)};
    }
  }`}
`;

const StyledCardActionArea = styled(CardActionArea)<CustomCardProps>`
  align-items: stretch;
  display: flex;
  flex-direction: ${({ direction }) => direction};
  height: 100%;
`;

const StyledGrid = styled(Grid)<{ isMobile: boolean }>`
  > * {
    &:not(:last-child) {
      margin-right: ${({ isMobile }) => (isMobile ? 'initial' : '2.5rem')};
      margin-bottom: ${({ isMobile }) => (isMobile ? '2rem' : 'initial')};
    }
  }
`;

type EventDetails = {
  dates?: string;
  organizer?: string;
  location?: string;
};
export interface HackathonCardProps {
  title: string;
  description?: string;
  subsections?: EventDetails;
  location?: string;
  imageUrl?: string;
  cardDirection?: 'column' | 'row';
  hasBorder?: boolean;
  href?: string;
  cardType?: 'gitcoin' | 'devpost' | 'despace';
  svgProps?: React.SVGProps<SVGSVGElement>;
  target?: '_blank' | '_self';
}

export interface HackathonCardsProps {
  cards: HackathonCardProps[];
}

const HackathonCard: React.FC<HackathonCardProps> = ({
  title,
  description,
  subsections,
  imageUrl,
  cardDirection = 'column',
  hasBorder = true,
  href,
  cardType,
  target = '_blank',
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const icons = {
    gitcoin: <MemoGitcoinIcon width={isMobile ? '70%' : '9rem'} />,
    devpost: <MemoDevpostIcon width={isMobile ? '70%' : '9rem'} />,
    despace: <MemoDespaceIcon width={isMobile ? '70%' : '9rem'} />,
  };

  const clickHandler = (link: string) => {
    window.open(link, target);
  };
  return (
    <CustomCard
      direction={isTablet ? 'column' : cardDirection}
      theme={theme}
      elevation={0}
      className={hasBorder ? 'hasBorder' : undefined}
    >
      <StyledCardActionArea
        disableRipple
        theme={theme}
        direction={isTablet ? 'row' : cardDirection}
        onClick={() => clickHandler(href ?? '#')}
      >
        {imageUrl && (
          <CardMedia
            style={{
              minWidth: cardDirection === 'row' || isTablet ? '33%' : 'initial',
            }}
            component="img"
            alt={title}
            image={imageUrl}
            title={title}
          />
        )}
        {!imageUrl && (
          <CardMedia
            style={{
              padding: '10px',
              minWidth: cardDirection === 'row' || isTablet ? '33%' : 'initial',
              backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.purple.main} 100%)`,
            }}
            component="div"
          >
            {cardType ? icons[cardType] : <BiImage />}
          </CardMedia>
        )}
        <CardContent>
          <Typography marginBottom="0.5rem" size="h5">
            {title}
          </Typography>
          {description && (
            <Typography color="textSecondary" size="p" minHeight="4.5rem" component="div">
              {description}
            </Typography>
          )}
          {subsections && (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto' }}>
              <Typography color="textSecondary" size="p">
                {Object.values(subsections).join(' \u2022 ')}
              </Typography>
            </div>
          )}
        </CardContent>
      </StyledCardActionArea>
    </CustomCard>
  );
};

export const HackathonCards: React.FC<HackathonCardsProps> = ({ cards }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <StyledGrid
      isMobile={isTablet}
      container
      flexDirection={isTablet ? 'column' : 'row'}
      flexWrap={isTablet ? 'wrap' : 'inherit'}
    >
      {cards.map((card, _, arr) => {
        const direction = arr.length % 2 === 1 ? 'column' : undefined;
        return <HackathonCard key={card.title} {...card} cardDirection={direction} />;
      })}
    </StyledGrid>
  );
};
