import * as React from 'react';

function FeaturesThird({ fill, stroke, fillOpacity, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="79"
      height="53"
      viewBox="0 0 79 53"
      fill="none"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.3333 53C40.8768 53 52.6667 41.1355 52.6667 26.5C52.6667 11.8645 40.8768 0 26.3333 0C11.7898 0 0 11.8645 0 26.5C0 41.1355 11.7898 53 26.3333 53Z"
        fill={fill ?? 'white'}
        fillOpacity={fillOpacity ?? '0.5'}
      />
      <path
        d="M78.9974 0H52.6641V53H78.9974V0Z"
        fill={fill ?? 'white'}
        fillOpacity={fillOpacity ?? '0.5'}
      />
      <path
        d="M38 38.5H38.5V38V15V14.5H38L15 14.5H14.5V15L14.5 38V38.5H15H38Z"
        stroke={stroke ?? '#39FFC2'}
      />
    </svg>
  );
}

const MemoFeaturesThird = React.memo(FeaturesThird);
export default MemoFeaturesThird;
