import * as React from 'react';

function Participant({ fill, stroke, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305 200" {...rest}>
      <path fill={fill ?? '#fff'} d="M0 0h305v200H0z" />
      <linearGradient
        id="prefix__a"
        gradientUnits="userSpaceOnUse"
        x1={204.84}
        y1={89.274}
        x2={309.7}
        y2={110.778}
        gradientTransform="matrix(1 0 0 -1 0 200)"
      >
        <stop offset={0} stopColor="#2e58eb" />
        <stop offset={1} stopColor="#883aaf" />
      </linearGradient>
      <path fill="url(#prefix__a)" d="M209.5 0h95.2v200h-95.2z" />
      <linearGradient
        id="prefix__b"
        gradientUnits="userSpaceOnUse"
        x1={82.871}
        y1={-0.34}
        x2={12.97}
        y2={94.757}
        gradientTransform="matrix(1 0 0 -1 0 200)"
      >
        <stop offset={0} stopColor="#2e58eb" />
        <stop offset={1} stopColor="#883aaf" />
      </linearGradient>
      <path fill="url(#prefix__b)" d="M0 104.8h95.2V200H0z" />
      <linearGradient
        id="prefix__c"
        gradientUnits="userSpaceOnUse"
        x1={106.406}
        y1={27.819}
        x2={198.579}
        y2={67.515}
        gradientTransform="matrix(1 0 0 -1 0 200)"
      >
        <stop offset={0} stopColor="#2e58eb" />
        <stop offset={1} stopColor="#883aaf" />
      </linearGradient>
      <path fill="url(#prefix__c)" d="M104.8 104.8H200V200h-95.2z" />
      <linearGradient
        id="prefix__d"
        gradientUnits="userSpaceOnUse"
        x1={212.405}
        y1={82.581}
        x2={304.579}
        y2={122.277}
        gradientTransform="matrix(-1 0 0 1 410.762 -54.762)"
      >
        <stop offset={0} stopColor="#2e58eb" />
        <stop offset={1} stopColor="#883aaf" />
      </linearGradient>
      <circle cx={152.4} cy={47.6} r={47.6} fill="url(#prefix__d)" />
      <circle
        cx={47.6}
        cy={152.4}
        r={46.1}
        fill="none"
        stroke={stroke ?? '#39ffc2'}
        strokeWidth={3}
      />
    </svg>
  );
}
const MemoParticipant = React.memo(Participant);
export default MemoParticipant;
