import * as React from 'react';

function FeaturesFirst({ fill, stroke, fillOpacity, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="51"
      height="58"
      viewBox="0 0 51 58"
      fill="none"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="47"
        width="50"
        height="10"
        fill={fill ?? 'white'}
        fillOpacity={fillOpacity ?? '0.5'}
      />
      <rect
        y="32"
        width="50"
        height="10"
        fill={fill ?? 'white'}
        fillOpacity={fillOpacity ?? '0.5'}
      />
      <rect
        y="17"
        width="50"
        height="10"
        fill={fill ?? 'white'}
        fillOpacity={fillOpacity ?? '0.5'}
      />
      <rect
        x="50.5"
        y="0.5"
        width="57"
        height="26"
        transform="rotate(90 50.5 0.5)"
        stroke={stroke ?? '#39FFC2'}
      />
    </svg>
  );
}

const MemoFeaturesFirst = React.memo(FeaturesFirst);
export default MemoFeaturesFirst;
