import React, { useState } from 'react';
import styled from '@emotion/styled';
import { scroller } from 'react-scroll';
import { Container, Grid, Theme, useTheme } from '@material-ui/core';
import { MainPage } from '../MainPage/MainPage';
import { Hero, HeroProps } from '../../design-system/molecules/Hero';
import { ContentCard, ContentCardProps } from '../../design-system/molecules/ContentCard';
import { HackathonCards } from '../../design-system/molecules/HackathonCard';
import { HackathonCardProps } from '../../design-system/molecules/HackathonCard/HackathonCard';
import { Typography } from '../../design-system/atoms/Typography';
import { Usp, UspProps } from '../../design-system/molecules/Usp';
import { ResourceCardProps } from '../../design-system/molecules/ResourceCard';
import MemoFeaturesFirst from '../../icons/FeaturesFirst';
import MemoFeaturesSecond from '../../icons/FeaturesSecond';
import MemoFeaturesThird from '../../icons/FeaturesThird';
import MemoFeaturesFourth from '../../icons/FeaturesFourth';

interface ContainerStyledProps {
  theme: Theme;
}

const ContainerStyled = styled(Container)<ContainerStyledProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(30)};
  margin-bottom: ${({ theme }) => theme.spacing(30)};
`;

const ContentCardStyled = styled(ContentCard)`
  border: none;
`;

const HeroData: HeroProps = {
  title: 'Build the next big thing on Tezos',
  subtitle:
    'Hackathons provide an opportunity to shape the the future of the Tezos platform and the world it benefits. Whether you’re a tech builder or an advocate, you’ll be able to contribute to the ecosystem and join growing global community.',
  actions: [
    {
      label: 'Organize',
      href: '/organizer',
    },
    {
      label: 'Participate',
      href: '/participant',
    },
    {
      label: 'Partner',
      href: '/partnership-application',
    },
  ],
  backgroundImage: '/assets/images/hexagons-blurred.svg',
  backgroundPosition: 'right bottom',
  backgroundImageSize: '235px',
  hasExtraBackgroundImage: true,
  isDoubleExtraBackgroundImage: true,
};

const Cards: ContentCardProps[] = [
  {
    title: 'Want to build the next big thing on Tezos?',
    description: 'View resources and information for developers to prepare for hackathons on Tezos',
    actionTitle: 'View Participant Resources',
    href: '/participant',
    cardDirection: 'row-reverse',
    cardType: 'participant',
  },
  {
    title: 'Want to enable tech builders?',
    description:
      'Facilitate ecosystem development, foster community, drive change in the Tezos ecosystem and beyond. Check out our recommendations on how to organize a successful hacakthon and how to get funding.',
    actionTitle: 'View the Organizer Starter Kit',
    href: '/organizer',
    cardDirection: 'row',
    cardType: 'organizer',
  },
  {
    title: 'Looking to partner?',
    description:
      'Whether you’re a university looking for research opportunities or a company looking for enterprise solutions, we’ll be able to facilitate a partnership.',
    actionTitle: 'Get in Touch',
    href: '/partnership-application',
    cardDirection: 'row-reverse',
    cardType: 'partner',
  },
];

export const HackCards: HackathonCardProps[] = [
  {
    title: 'Open the Metaverse',
    description: 'A two-day online hackathon exploring digital art and fashion in web3',
    subsections: {
      dates: '10 - 12 Dec',
      organizer: 'despace berlin',
      location: 'Online',
    },
    cardType: 'despace',
    href: '/featured/open-the-metaverse',
    target: '_self',
  },
  {
    title: 'NFT Me, You Can DAO It',
    description: 'A virtual hackathon to expand the NFT and DAO ecosystem on Tezos',
    subsections: {
      dates: '21 Jul - 21 Aug',
      organizer: 'Gitcoin',
      location: 'Online',
    },
    cardType: 'gitcoin',
    href: 'https://gitcoin.co/hackathon/tezos/',
    target: '_self',
  },
];

export const FeaturedHackathons = (): React.ReactElement => (
  <Grid container flexDirection="column" marginBottom="12.5rem" id="featured-hackathons">
    <Typography size="h2" marginBottom="3.5rem">
      Featured Hackathons
    </Typography>
    <HackathonCards cards={HackCards} />
  </Grid>
);

const UspTezos = ({ theme }: { theme: Theme }) => {
  const CardStyle: Omit<ResourceCardProps, 'title'> = {
    hasBackground: false,
    cardDirection: 'column',
    fontColor: theme.palette.neutral.white100,
  };

  const UspCardsItem: ResourceCardProps[] = [
    {
      title: 'Self-Governing',
      description:
        'All stakeholders may participate in network upgrades by evaluating, proposing, or approving amendments.',
      imageUrl: <MemoFeaturesFirst />,
      ...CardStyle,
    },
    {
      title: 'Eco-Friendly',
      description:
        'Tezos’ Proof-of-Stake mechanism allows validation of the blockchain through staking, a highly energy efficient solution.',
      imageUrl: <MemoFeaturesSecond />,
      ...CardStyle,
    },
    {
      title: 'Secure',
      description:
        'Tezos is designed to provide the safety and code correctness required for assets and other high value use cases.',
      imageUrl: <MemoFeaturesThird />,

      ...CardStyle,
    },
    {
      title: 'Built to Last',
      description:
        'With its self-amendable protocol, Tezos can adopt new technological innovations smoothly as they emerge.',
      imageUrl: <MemoFeaturesFourth />,
      ...CardStyle,
    },
  ];

  const UspData: UspProps = {
    title: 'Why Tezos',
    uspCards: UspCardsItem,
    backgroundGradient: `linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.purple.dark} 100%)`,
    backgroundPosition: 'right bottom',
    backgroundImageSize: '235px',
    hasExtraBackgroundImage: true,
    fontColor: theme.palette.neutral.white100,
  };

  return <Usp {...UspData} />;
};

const HomePage = (): React.ReactElement => {
  const [heroHeight, setHeroHeight] = useState(0);
  const theme = useTheme();
  const fieldRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    const myId = window.location.hash.slice(1);
    if (myId && fieldRef.current) {
      scroller.scrollTo(fieldRef.current.id, {
        duration: 1000,
        smooth: true,
        offset: -85,
      });
    }
  }, []);

  return (
    <MainPage heroSize={heroHeight}>
      <Hero
        {...HeroData}
        getHeroHeight={(h) => setHeroHeight(h)}
        fontColor={theme.palette.neutral.white100}
        backgroundGradient={`linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.purple.dark} 100%)`}
      />
      <ContainerStyled maxWidth="xl" theme={theme} ref={fieldRef} id="featured-container">
        <FeaturedHackathons />
      </ContainerStyled>
      <UspTezos theme={theme} />
      <ContainerStyled maxWidth="xl" theme={theme}>
        <Grid container flexDirection="column" spacing={30}>
          {Cards.map((card, i) => (
            <Grid item container xs={12} key={`${card.title}-${i}`}>
              <ContentCardStyled {...card} />
            </Grid>
          ))}
        </Grid>
      </ContainerStyled>
    </MainPage>
  );
};

export default HomePage;
