import * as React from 'react';

function DevpostIcon({ fill, stroke, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 600 116.5"
      xmlSpace="preserve"
      {...rest}
    >
      <path
        fill={fill ?? '#3acce6'}
        d="M566.4 0h-26.6l33.6 58.2-33.6 58.3h26.6L600 58.2zM33.6 0h26.6L26.6 58.2l33.6 58.3H33.6L0 58.2z"
      />
      <path fill="#fff" d="M307.7 36.6h-16.3v18.2h16.5c6.6 0 10.1-3 10.1-9.2 0-6.2-4.1-9-10.3-9z" />
      <ellipse fill="#fff" cx={374.9} cy={58.2} rx={16.2} ry={22.4} />
      <path
        fill="#fff"
        d="M549.8 0H50.2L16.6 58.2l33.6 58.3h499.6l33.6-58.3L549.8 0zM84.2 93.6H63V22.9h22.1c20.2 0 35.2 9.5 35.2 35.4 0 24.8-18 35.3-36.1 35.3zm102.9-.1h-50.6V22.9h49.1v13.8h-35v12.2h20.3v13.7h-20.3v17.1h36.5v13.8zm49.9.1h-13.7l-25.1-70.7h15.7l13.3 40.9c1.3 3.9 2.7 9.1 3.3 11.6.6-2.6 1.9-7.5 3.2-11.6l13-40.9h15.5L237 93.6zm69.9-25.1h-15.6v25.1h-14.1V22.9h30.9c13.3 0 24.3 7.4 24.3 22.4.1 16.4-10.8 23.2-25.5 23.2zm68 26.2c-19.3 0-30.7-15-30.7-36.5s11.4-36.5 30.7-36.5c19.3 0 30.7 15 30.7 36.5s-11.4 36.5-30.7 36.5zm69.7 0c-11.8 0-22.1-4.9-27.5-12.8l10.2-9.1c5 5.8 11.3 8.1 18.1 8.1 8.4 0 12.3-2.3 12.3-7.7 0-4.5-2.4-6.6-15.8-9.6-13-3-22.1-7.2-22.1-21.1 0-12.8 11.3-20.7 25.3-20.7 11 0 19.1 4.1 25.8 11.1l-10.2 9.9c-4.5-4.6-9.6-7.3-16.1-7.3-7.6 0-10.1 3.2-10.1 6.4 0 4.4 3 5.9 14.2 8.5 11.2 2.6 23.6 6.5 23.6 21.8.1 13.4-8.4 22.5-27.7 22.5zM537 36.8h-20.4v56.8h-14.1V36.8h-20.3V22.9H537v13.9z"
      />
      <path
        fill="#fff"
        d="M84.7 36.6h-7.5v43.2h7c14.8 0 21.6-8.7 21.6-21.6 0-14.4-6.2-21.6-21.1-21.6z"
      />
    </svg>
  );
}

const MemoDevpostIcon = React.memo(DevpostIcon);
export default MemoDevpostIcon;
