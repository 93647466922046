import React from 'react';
import styled from '@emotion/styled';
import { BiImage } from 'react-icons/bi';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Button } from '../../atoms/Button';
import MemoOrganizer from '../../../icons/Organizer';
import MemoPartnership from '../../../icons/Partnership';
import MemoParticipant from '../../../icons/Participant';
import MemoOrganizerCard from '../../../icons/OrganizerCard';
import MemoParticipantCard from '../../../icons/ParticipantCard';
import MemoPartnershipCard from '../../../icons/PartnershipCard';

interface CustomCardProps {
  theme: Theme;
  direction: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  isMobile: boolean;
}
const CustomCard = styled(Card)<CustomCardProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  width: 100%;

  &.hasBorder {
    border: solid 1px ${({ theme }) => theme.palette.grey[300]};
  }

  .MuiCardMedia-root {
    height: ${({ theme }) => theme.spacing(23.875)};
    background-color: ${({ theme }) => theme.palette.secondary.light};
    font-size: ${({ theme }) => theme.spacing(23.125)};
    color: ${({ theme }) => theme.palette.grey[300]};
    display: flex;
    align-items: center;
    justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-end')};
  }

  &[direction='column'],
  &[direction='column-reverse'] {
    .MuiCardMedia-root {
      width: 100%;
    }
  }

  &.MuiCard-root .MuiCardContent-root {
    font-weight: normal;
  }

  ${({ theme }) => `${theme.breakpoints.down('md')} {
    &.MuiCard-root {
      &:not(hasBorder){
        .MuiCardContent-root {
          padding-top: ${theme.spacing(6)} !important;
          padding: 0;
        }
      }

      &.hasBorder{
        .MuiCardContent-root {
          padding: ${theme.spacing(4)};
        }
      }
    }

  }`}

  ${({ theme }) => `${theme.breakpoints.up('md')} {
    &.MuiCard-root{
      .MuiCardContent-root {
        padding: ${theme.spacing(6)};
      }

      &[direction='column'] .MuiCardContent-root {
        padding-top: ${theme.spacing(4)};
      }
  
      &[direction='column-reverse'] .MuiCardContent-root {
        padding-bottom: ${theme.spacing(4)};
      }
  
      &[direction='row'] .MuiCardContent-root {
        padding-left: ${theme.spacing(4)};
      }
  
      &[direction='row-reverse'] .MuiCardContent-root {
        padding-right: ${theme.spacing(4)};
      }

      &.row-card {
        align-items: center;
        justify-content: space-between;

        .MuiCardMedia-root {
          flex: 1;
          max-width: ${theme.spacing(48)};
        }

        .MuiCardContent-root {
          max-width: ${theme.spacing(48)};
        }
      }
  }`}

  h5 {
    margin-bottom: 0;
  }
`;

export interface ContentCardProps {
  title: string;
  titleSize?: 'large' | 'medium';
  description?: string;
  actionTitle?: string;
  imageUrl?: string;
  cardDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  hasBorder?: boolean;
  href?: string;
  isCard?: boolean;
  cardType?:
    | 'organizer'
    | 'partner'
    | 'participant'
    | 'organizerCard'
    | 'partnerCard'
    | 'participantCard';
}

export const ContentCard: React.FC<ContentCardProps> = ({
  title,
  titleSize = 'large',
  description,
  actionTitle,
  imageUrl,
  cardDirection = 'column',
  hasBorder = false,
  href,
  cardType,
  isCard = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const icons = {
    organizer: <MemoOrganizer width={isTablet ? '12.5rem' : isMobile ? 'auto' : '19.0475rem'} />,
    partner: <MemoPartnership width={isTablet ? '12.5rem' : isMobile ? 'auto' : '19.0475rem'} />,
    participant: (
      <MemoParticipant width={isTablet ? '12.5rem' : isMobile ? 'auto' : '19.0475rem'} />
    ),
    organizerCard: (
      <MemoOrganizerCard
        height={`${isMobile ? '100%' : '9.375rem'}`}
        style={{ marginRight: isMobile ? 'inherit' : '6.5rem' }}
      />
    ),
    participantCard: (
      <MemoParticipantCard
        height={`${isMobile ? '100%' : '9.375rem'}`}
        style={{ marginRight: isMobile ? 'inherit' : '6.5rem' }}
      />
    ),
    partnerCard: (
      <MemoPartnershipCard
        height={`${isMobile ? '100%' : '9.375rem'}`}
        style={{ marginRight: isMobile ? 'inherit' : '6.5rem' }}
      />
    ),
  };
  return (
    <CustomCard
      isMobile={isMobile}
      direction={isTablet || isMobile ? 'column' : cardDirection}
      theme={theme}
      elevation={0}
      square
      className={[
        hasBorder ? 'hasBorder' : undefined,
        cardDirection === 'row' || cardDirection === 'row-reverse' ? 'row-card' : undefined,
      ].join(' ')}
    >
      {imageUrl && <CardMedia component="img" alt={title} image={imageUrl} title={title} />}
      {!imageUrl && (
        <CardMedia
          component="div"
          style={{
            margin: isTablet || isMobile ? 'auto' : undefined,
            height: isMobile ? 'max-content' : '9.375rem',
            backgroundColor: isCard ? 'inherit' : '#ffff',
            backgroundImage: isCard
              ? `linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.purple.main} 100%)`
              : 'none',
          }}
        >
          {cardType ? icons[cardType] : <BiImage />}
        </CardMedia>
      )}
      <CardContent>
        <Typography gutterBottom variant={titleSize === 'large' ? 'h3' : 'h5'} component="h5">
          {title}
        </Typography>
        {description && (
          <Typography color="textSecondary" component="p" sx={{ marginY: 3 }}>
            {description}
          </Typography>
        )}
        {actionTitle && (
          <CardActions sx={{ padding: 0, paddingTop: 1.5 }}>
            <Button label={actionTitle} href={href} />
          </CardActions>
        )}
      </CardContent>
    </CustomCard>
  );
};
