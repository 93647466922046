import * as React from 'react';

function FeaturesFourth({ fill, stroke, fillOpacity, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M60 31H30V61H60V31Z" fill={fill ?? 'white'} fillOpacity={fillOpacity ?? '0.5'} />
      <path
        d="M15 31C23.2843 31 30 24.2843 30 16C30 7.71573 23.2843 1 15 1C6.71573 1 0 7.71573 0 16C0 24.2843 6.71573 31 15 31Z"
        fill={fill ?? 'white'}
        fillOpacity={fillOpacity ?? '0.5'}
      />
      <path
        d="M45.9366 2.75633L45.5 1.97403L45.0634 2.75633L32.506 25.2563L32.091 26H32.9426H58.0574H58.909L58.494 25.2563L45.9366 2.75633Z"
        stroke={stroke ?? '#39FFC2'}
      />
    </svg>
  );
}

const MemoFeaturesFourth = React.memo(FeaturesFourth);
export default MemoFeaturesFourth;
