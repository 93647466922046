export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const getDisplayTimeRange = (startDate: Date, endDate: Date) => {
  const locales = 'en-GB';
  const fullOption: any = { year: 'numeric', month: 'short', day: 'numeric' };
  const noYearOption: any = { month: 'short', day: 'numeric' };
  const noMonthOption: any = { day: 'numeric' };

  if (startDate.getFullYear() === endDate.getFullYear()) {
    if (startDate.getMonth() === endDate.getMonth()) {
      return `${startDate.toLocaleDateString(
        locales,
        noMonthOption,
      )} - ${endDate.toLocaleDateString(locales, fullOption)}`;
    }
    return `${startDate.toLocaleDateString(locales, noYearOption)} - ${endDate.toLocaleDateString(
      locales,
      fullOption,
    )}`;
  }
  return `${startDate.toLocaleDateString(locales, fullOption)} - ${endDate.toLocaleDateString(
    locales,
    fullOption,
  )}`;
};
