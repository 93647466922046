import * as React from 'react';

function Partnership({ fill, stroke, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 305 200.2"
      {...rest}
    >
      <defs>
        <path id="prefix__a" d="M0 0h305v200H0z" />
      </defs>
      <clipPath id="prefix__b">
        <use xlinkHref="#prefix__a" overflow="visible" />
      </clipPath>
      <g clipPath="url(#prefix__b)">
        <path fill={fill ?? '#fff'} d="M0 0h305v200H0z" />
        <linearGradient
          id="prefix__c"
          gradientUnits="userSpaceOnUse"
          x1={211.662}
          y1={132.846}
          x2={303.502}
          y2={172.463}
          gradientTransform="matrix(1 0 0 -1 0 200)"
        >
          <stop offset={0} stopColor="#2e58eb" />
          <stop offset={1} stopColor="#883aaf" />
        </linearGradient>
        <path fill="url(#prefix__c)" d="M210 0h94.9v94.8H210z" />
        <linearGradient
          id="prefix__d"
          gradientUnits="userSpaceOnUse"
          x1={142.041}
          y1={-9.559}
          x2={268.265}
          y2={104.597}
          gradientTransform="matrix(1 0 0 -1 0 200)"
        >
          <stop offset={0} stopColor="#2e58eb" />
          <stop offset={1} stopColor="#883aaf" />
        </linearGradient>
        <path fill="url(#prefix__d)" d="M105 105h200v95.2H105z" />
        <linearGradient
          id="prefix__e"
          gradientUnits="userSpaceOnUse"
          x1={81.859}
          y1={105.261}
          x2={12.859}
          y2={200.261}
          gradientTransform="matrix(1 0 0 -1 0 200)"
        >
          <stop offset={0} stopColor="#2e58eb" />
          <stop offset={1} stopColor="#883aaf" />
        </linearGradient>
        <path fill="url(#prefix__e)" d="M0 0h94.9v94.8H0z" />
        <path
          fill="none"
          stroke={stroke ?? '#39ffc2'}
          strokeWidth={3}
          d="M105.3 1.5h93.2v92.2h-93.2z"
        />
      </g>
    </svg>
  );
}
const MemoPartnership = React.memo(Partnership);
export default MemoPartnership;
