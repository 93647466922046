import * as React from 'react';

function Organizer({ fill, stroke, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 305 200"
      {...rest}
    >
      <defs>
        <path id="prefix__a" d="M0 0h305v200H0z" />
      </defs>
      <clipPath id="prefix__b">
        <use xlinkHref="#prefix__a" overflow="visible" />
      </clipPath>
      <g clipPath="url(#prefix__b)">
        <path fill="#fff" d="M0 0h305v200H0z" />
        <linearGradient
          id="prefix__c"
          gradientUnits="userSpaceOnUse"
          x1={292.265}
          y1={201.438}
          x2={118.89}
          y2={104.764}
          gradientTransform="matrix(1 0 0 -1 0 200)"
        >
          <stop offset={0} stopColor="#2e58eb" />
          <stop offset={1} stopColor="#883aaf" />
        </linearGradient>
        <path fill="url(#prefix__c)" d="M104 0h201v95H104z" />
        <linearGradient
          id="prefix__d"
          gradientUnits="userSpaceOnUse"
          x1={79.173}
          y1={94.546}
          x2={15.154}
          y2={-0.441}
          gradientTransform="matrix(1 0 0 -1 0 200)"
        >
          <stop offset={0} stopColor="#2e58eb" />
          <stop offset={1} stopColor="#883aaf" />
        </linearGradient>
        <path fill="url(#prefix__d)" d="M0 105.2h94.8V200H0z" />
        <linearGradient
          id="prefix__e"
          gradientUnits="userSpaceOnUse"
          x1={130.084}
          y1={91.23}
          x2={173.696}
          y2={5.95}
          gradientTransform="matrix(1 0 0 -1 0 200)"
        >
          <stop offset={0} stopColor="#2e58eb" />
          <stop offset={1} stopColor="#883aaf" />
        </linearGradient>
        <path fill="url(#prefix__e)" d="M105 105h94.8v94.8H105z" />
        <linearGradient
          id="prefix__f"
          gradientUnits="userSpaceOnUse"
          x1={308.603}
          y1={-23.86}
          x2={403.495}
          y2={14.96}
          gradientTransform="matrix(-1 0 0 1 619.744 153.318)"
        >
          <stop offset={0} stopColor="#2e58eb" />
          <stop offset={1} stopColor="#883aaf" />
        </linearGradient>
        <circle cx={256.9} cy={151.7} r={47.4} fill="url(#prefix__f)" />
        <path
          fill="none"
          stroke={stroke ?? '#39ffc2'}
          strokeWidth={3}
          d="M53.6 53.6h196.1v91.8H53.6z"
        />
      </g>
    </svg>
  );
}

const MemoOrganizer = React.memo(Organizer);
export default MemoOrganizer;
