import * as React from 'react';

function ParticipantCard({ fill, opacity, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="prefix__participant_image"
      data-name="participant image"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 150"
      {...rest}
    >
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        transform="rotate(-45 74.539 49.585)"
        d="M58.42 33.46h32.25v32.25H58.42z"
      />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        d="M199.48 150l-22.47-22.47L154.54 150h44.94z"
      />
      <circle fill={fill ?? '#fff'} opacity={opacity ?? '.3'} cx={177.01} cy={50} r={23} />
      <circle fill={fill ?? '#fff'} opacity={opacity ?? '.3'} cx={126.5} cy={50} r={23} />
      <path
        d="M23 73a23 23 0 1123-23 23 23 0 01-23 23zm0-44.06A21.07 21.07 0 1044.08 50 21.09 21.09 0 0023 28.93z"
        fill={fill ?? '#39ffc2'}
      />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        d="M23 23A23 23 0 0046 0H0a23 23 0 0023 23z"
      />
      <circle fill={fill ?? '#fff'} opacity={opacity ?? '.3'} cx={177.01} cy={101} r={23} />
      <circle fill={fill ?? '#fff'} opacity={opacity ?? '.3'} cx={126.5} cy={101} r={23} />
      <circle fill={fill ?? '#fff'} opacity={opacity ?? '.3'} cx={74.54} cy={101} r={23} />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        d="M74.54 23a23 23 0 0023-23h-46a23 23 0 0023 23zM74.54 127a23 23 0 00-23 23h46a23 23 0 00-23-23zM23 127a23 23 0 00-23 23h46a23 23 0 00-23-23zM177 23a23 23 0 0023-23h-46a23 23 0 0023 23z"
      />
    </svg>
  );
}

const MemoParticipantCard = React.memo(ParticipantCard);
export default MemoParticipantCard;
