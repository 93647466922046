import * as React from 'react';

function OrganizerCard({
  fill,
  stroke,
  opacity,
  strokeWidth,
  strokeMiterlimit,
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 150"
      {...rest}
    >
      <path fill={fill ?? '#fff'} opacity={opacity ?? '.3'} d="M0 26.5h46v98H0z" />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        transform="rotate(-90 74.5 139)"
        d="M63.5 64.5h22v149h-22z"
      />
      <path
        fill="none"
        stroke={stroke ?? '#39ffc2'}
        strokeMiterlimit={strokeMiterlimit ?? 10}
        strokeWidth={strokeWidth ?? 2}
        d="M102 122V78h97v44z"
      />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        transform="rotate(-45 73.614 50.588)"
        d="M57.49 34.46h32.25v32.25H57.49z"
      />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        d="M199.52 150l-22.47-22.47L154.58 150h44.94z"
      />
      <circle fill={fill ?? '#fff'} opacity={opacity ?? '.3'} cx={74} cy={101} r={23} />
      <circle fill={fill ?? '#fff'} opacity={opacity ?? '.3'} cx={177} cy={50} r={23} />
      <path
        fill={fill ?? '#fff'}
        opacity={opacity ?? '.3'}
        d="M23 23A23 23 0 0046 0H0a23 23 0 0023 23z"
      />
    </svg>
  );
}

const MemoOrganizerCard = React.memo(OrganizerCard);
export default MemoOrganizerCard;
