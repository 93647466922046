import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { BiImage } from 'react-icons/bi';
import {
  Card,
  CardContent,
  CardMedia,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { openInNewTab } from '../../../utils/misc';

interface CustomCardProps {
  theme: Theme;
  direction: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  isMobile: boolean;
  contentCardPadding: number;
  isLink?: boolean;
}
const CustomCard = styled(Card)<CustomCardProps>`
  display: flex;
  background-color: transparent;
  flex-direction: ${({ direction }) => direction};
  width: 100%;
  height: 100%;
  cursor: ${({ isLink }) => (isLink ? 'pointer' : 'inherit')};
  &.hasBorder {
    border: solid 1px ${({ theme }) => theme.palette.grey[300]};
  }

  .MuiCardMedia-root {
    background-color: ${({ theme }) => theme.palette.secondary.light};
    color: ${({ theme }) => theme.palette.grey[300]};
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: ${({ theme }) => theme.spacing(8)};
      max-height: ${({ theme }) => theme.spacing(8)};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${({ theme }) => theme.spacing(8)};
      height: ${({ theme }) => theme.spacing(8)};
      & > svg {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &[direction='column'],
  &[direction='column-reverse'] {
    .MuiCardMedia-root {
      width: 100%;
    }
  }

  &.row-card {
    .MuiCardMedia-root {
      flex: 0 0 ${({ theme }) => theme.spacing(15.5)};
    }
  }

  &.MuiCard-root .MuiCardContent-root {
    font-weight: normal;
    padding: ${({ theme, contentCardPadding }) => theme.spacing(contentCardPadding)};
    padding-top: ${({ theme }) => theme.spacing(3)};
  }
`;

export interface ResourceCardProps {
  title: string;
  description?: string;
  hasBackground?: boolean;
  imageUrl?: string | ReactNode;
  cardDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  hasBorder?: boolean;
  fontColor?: string;
  href?: string;
}

export const ResourceCard: React.FC<ResourceCardProps> = ({
  title,
  description,
  hasBackground = true,
  imageUrl,
  fontColor,
  cardDirection = 'row',
  hasBorder = false,
  href,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const contentCardPadding = !hasBackground && !hasBorder && cardDirection === 'column' ? 0 : 3;

  const onClick = React.useCallback(() => {
    if (href) {
      openInNewTab(href);
    }
  }, [href]);
  return (
    <CustomCard
      isMobile={isMobile}
      direction={cardDirection}
      contentCardPadding={contentCardPadding}
      theme={theme}
      elevation={0}
      square
      className={[
        hasBorder ? 'hasBorder' : undefined,
        cardDirection === 'row' || cardDirection === 'row-reverse' ? 'row-card' : undefined,
      ].join(' ')}
      onClick={onClick}
      isLink={!!href}
    >
      <CardMedia
        component="div"
        style={{
          backgroundColor: 'transparent',
          backgroundImage: hasBackground
            ? `linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.purple.main} 100%)`
            : 'none',
        }}
      >
        {imageUrl ? (
          <>
            {' '}
            {typeof imageUrl === 'string' ? (
              <img src={imageUrl} alt={title} />
            ) : (
              <div>{imageUrl}</div>
            )}
          </>
        ) : (
          <BiImage />
        )}
      </CardMedia>
      <CardContent>
        <Typography color={fontColor || 'textPrimary'} gutterBottom variant="h6" component="h6">
          {title}
        </Typography>
        {description && (
          <Typography color={fontColor || 'textSecondary'} component="p" sx={{ marginTop: 0.5 }}>
            {description}
          </Typography>
        )}
      </CardContent>
    </CustomCard>
  );
};
